<template>
  <div>


    <q-form class="my-card">
      <q-card>
        <q-card-section>
          <b>
            Please enter your details in order to log in.
          </b>
        </q-card-section>

        <!--             email + validation-->
        <q-card-section>
          <q-input
              outlined
              ref="email"
              color="black"
              v-model="email"
              :rules="[ val => val && val.length > 0 || 'Please enter your email']"
              label="Email*"
              lazy-rules
              type="email">
            <template v-slot:append>
              <q-icon name="mail"/>
            </template>
          </q-input>
        </q-card-section>

        <!--             password + validation-->
        <q-card-section>
          <q-input
              outlined
              ref="password"
              color="black"
              v-model="password"
              :rules="[ val => val && val.length > 0 || 'Please enter your password']"
              :type="showPassword ? 'password' : 'text'"
              label="Password*"
              lazy-rules>
            <template v-slot:append>
              <q-icon
                  :name="showPassword ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="showPassword = !showPassword"
              />
            </template>
          </q-input>
        </q-card-section>


        <!--              submit button-->
        <q-card-section>
          <q-btn @click="submit" color="black" class="text-primary login-btn" label="Login" outline/>
        </q-card-section>
      </q-card>

      <!--                switch to register page -->
      <q-card-section class="bottom-text">
        <b class="q-mr-md">Not a member yet? click here to </b>
        <q-btn to="/register" flat class="register-btn" label="Register"/>
      </q-card-section>


      <!--                          invalid details message-->
      <q-card-section class="invalid-creds" v-if="invalid">
        <b>Invalid Email or Password. Please try again.</b>
      </q-card-section>
    </q-form>


  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'

export default {
  computed: {...mapState('auth', ['user'])},
  name: "Form",
  data() {
    return {
      email: "",
      password: "",
      invalid: false,
      showPassword: true
    }
  },
  methods: {
    ...mapActions('auth', ['loginUser']),
    async submit() {
      try {
        await this.loginUser({
          email: this.email,
          password: this.password
        })
        if (JSON.parse(sessionStorage.getItem('isLogin'))) {
          //user logged in.
          await this.$router.push('/market-tracker');
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  created() {
    if (JSON.parse(sessionStorage.getItem('isLogin'))) this.$router.push('/market-tracker')
  }
}
</script>

<style scoped>


.my-card {
  width: 500px;
  height: 320px;
}

.login-btn {
  margin-top: 10px;
}

.register-btn {
  color: #0004ff;
  width: 60px;
  margin-bottom: 2px;
}

.invalid-creds {
  color: red;
  font-size: 18px;
}

</style>
